<!--
This is the Footer component. The Footer component will contain crediting,
help, TOS, TOC, contact links.
-->

<template>

  <div>
    <footer class="footer">
       <div class="foobar">
         <!--TODO: THERE WILL BE A POP UP FOR ALL THE BUTTONS -->
        <button>Support</button>
        <button>Rules of Conduct</button>
        <button>Terms of Services</button>
        <button>Contributions</button>
        <span>&copy;Citopia</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: #ffffff;
  bottom:0;
  width:100%;
  height: 30px;
}
.foobar {
  display: flex;
  padding: 0px 15px;
  height: 30px;
  align-items: center;
}
button {
  text-decoration: none;
  margin-right: 2%;
  border: none;
  background-color: white;
  cursor: pointer;
}
span {
  margin-left: auto;
}
</style>