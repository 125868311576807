<template>

  <div class="UserBlog">
      <h1>Blog</h1>
  </div>

</template>

<script>

export default {
  name: "UserBlog"
}
</script>
