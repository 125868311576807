<template>
  <div class="categoriesbox">

    <div class="column1">
      <div class="category-icon">
        <font-awesome-icon icon="fa-regular fa-comments" size="3x"/>
      </div>

      <div class="category-details">
        <router-link v-bind:to="'/forum/' + category.id + '-' + category.title">{{ category.title }}</router-link>
        <div>{{ category.description }}</div>
      </div>
    </div>

    <div class="column2">
        {{ category.no_threads }}
    </div>

    <div class="column3">
        <div v-if="category.lastactive != null">
          <router-link v-bind:to="'/forum/thread/' + category.lastactive.id">{{
              category.lastactive.title
            }}
          </router-link>
          <br/>
          {{ category.lastactive.author }} - {{ humanDateElapsed(utcToLocal(new Date(category.lastactive.created))) }} ago
        </div>
      <div v-else>
        Nothing here.
      </div>
    </div>


  </div>
</template>

<script>
import DateConv from "@/functions/date";

export default {
  name: "Category",
  mixins: [DateConv],
  props: {
    category: Object,
    lastactive:{}
  },
}
</script>

<style lang="scss" scoped>

.categoriesbox {
  display: flex;
  padding: 15px;

  //text-align: center;
  border-bottom: 1px solid grey;

  & a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 23px;
  }

  & .category-icon {
    padding-right: 30px;

  }
}

.column1 {
  display: flex;
  flex: 50%;
  padding-right: 15px;
  justify-content: left;

}
.column2 {
  flex: 30%;
  text-align: center;
}
.column3 {
  flex: 20%;
  text-align: center;
}

</style>