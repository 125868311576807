/*
A module which can be included in a component by extending it which allows for easy date manipulation.
*/

export default {
    methods: {
        utcToLocal(date) {
            const localOffset = date.getTimezoneOffset() * 60000;
            const localTime = date.getTime();
            return new Date(localTime - localOffset);
        },
        humanDateElapsed(dt) {
            const ms = Date.now() - dt.getTime();
            if (ms >= 3.156e+10) {
                const div = Math.floor(ms / 3.156e+10);
                return `${div} year${div === 1 ? '' : 's'}`;
            } else if (ms >= 2.628e+9) {
                const div = Math.floor(ms / 2.628e+9);
                return `${div} month${div === 1 ? '' : 's'}`;
            } else if (ms >= 6.048e+8) {
                const div = Math.floor(ms / 6.048e+8);
                return `${div} week${div === 1 ? '' : 's'}`;
            } else if (ms >= 8.64e+7) {
                const div = Math.floor(ms / 8.64e+7);
                return `${div} day${div === 1 ? '' : 's'}`;
            } else if (ms >= 3.6e+6) {
                const div = Math.floor(ms / 3.6e+6);
                return `${div} hour${div === 1 ? '' : 's'}`;
            } else if (ms >= 60000) {
                const div = Math.floor(ms / 60000);
                return `${div} minute${div === 1 ? '' : 's'}`;
            } else {
                const div = Math.floor(ms / 1000);
                return `${div} second${div === 1 ? '' : 's'}`;
            }
        },
    },
};