<template>
  <div class="thread-container">
    <div class="column-reply-avatar" style="display: flex; align-items: center;">
      <img src="https://media.discordapp.net/attachments/907237807184048139/933044287682248724/1.png" class="avatar">
    </div>

    <div class="column-reply-title">
      <div style="display: flex; flex-direction: column;">
        <router-link v-bind:to="'/forum/thread/' + thread.id">{{ thread.title }}</router-link>
        <div>by @{{ thread.author }}</div>
      </div>
    </div>

    <div class="column-reply-content" style="display: flex; align-items: center; justify-content: center;">
      <div>{{ thread.replies }}</div>
    </div>

    <div class="column-reply-creationDate" style="display: flex;justify-content: center; flex-direction: column;">
      <div>{{ humanDateElapsed(utcToLocal(new Date(thread.posts[0].created))) }} ago</div>
    </div>

  </div>
</template>

<script>
import DateConv from "@/functions/date";

export default {
  name: "Thread",
  mixins: [DateConv],
  props: {
    thread: Object,
    posts: {},
  }
}

</script>

<style scoped>
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-position: top center;
  object-fit: cover;
}
.column-reply-avatar {
  flex: 10%;
}
.column-reply-title {
  flex: 50%;
}
.column-reply-content{
  flex: 20%;
  text-align: center;
}
.column-reply-creationDate {
  flex: 20%;
}
.thread-container {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid grey;
}
.categoriesbox a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}
</style>