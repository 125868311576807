<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
                <div v-html="markedSanitize(value)"></div>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { marked } from 'marked';
import DOMPURIFY from 'dompurify';
  export default {
    name: 'Modal',
    props: {
        value: String,
    },
    methods: {
      close() {
        this.$emit('close');
      },
        markedSanitize(content) {
        const htmlcontent = marked(content)
        const sanitizedcontent = DOMPURIFY.sanitize(htmlcontent)
        return sanitizedcontent
        }
    }
  };
</script>

<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 3px 3px 0px rgb(0, 0, 0, 0.3);
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 50%;
    text-align: left;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    color: #904448;
    justify-content: space-between;
  }


  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #904448;
    background: transparent;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  .forumbutton {
    text-decoration: none;
    background-color: #ffdcde;
    border-radius: 5px;
    box-shadow: 3px 3px 0px rgb(0, 0, 0, 0.3);
    text-align: center;
    padding: 5px 30px;
    color: black;
    font-weight: bold;
    border: none;
    margin-bottom: 1%;
    cursor: pointer;
}
</style>