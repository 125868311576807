import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'

import Forum from '../views/Forum/Forum.vue'
import ForumCategory from '../views/Forum/ForumCategory.vue'
import ForumNewThread from '../views/Forum/ForumNewThread.vue'
import ForumThread from '../views/Forum/ForumThread.vue'

import Games from '../views/Games/Games.vue'
import SnakeGame from '../views/Games/SnakeGame.vue'

import User from '../views/Profile/User.vue'
import UserProfile from '../views/Profile/UserProfile.vue'
import UserBlog from '../views/Profile/UserBlog.vue'

import PageNotFound from '../views/PageNotFound.vue'

const routes = [

  {
    path: '/explore',
    name: 'Home',
    component: Home
  },

  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/forum',
    name: 'Forum',
    component: Forum,

    // child doesn't work :(
    // children: [
    //   {
    //     path: 'category/:categoryId',
    //     name: 'ForumCategory',
    //     component: ForumCategory,
    //   }
    // ]
  },

  {
    path: '/games/snake',
    name: 'SnakeGame',
    component: SnakeGame
  },

  {
    path: '/games',
    name: 'Games',
    component: Games
  },

  {
    path: '/forum/:categoryId-:categoryName',
    name: 'ForumCategory',
    component: ForumCategory,
  },

  {
    path: '/forum/thread/:threadId',
    name: 'ForumThread',
    component: ForumThread,
  },

  {
    path: '/forum/:categoryId-:categoryName/new',
    name: 'ForumNewThread',
    component: ForumNewThread
  },

  {
    path: '/profile/:userId',
    name: 'User',
    component: User,
    children: [
      {
        path: '',
        name: 'UserProfile',
        component: UserProfile
      },

      {
        path: 'blog',
        name: 'UserBlog',
        component: UserBlog
      }
    ]
  },

// page not found, put as last
{
  path: '/:pathMatch(.*)',
  component: PageNotFound
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
