<template>

  <div class="UserProfile">
      <h1>Profile</h1>

      <p>
        {{ state.user.profile_description }}
      </p>
  </div>

</template>

<script>

import { reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { user } from '../../assets/dummy';

export default {
  name: "UserProfile",

  setup() {
    const route = useRoute();
    const userId = computed(() => route.params.userId )

    // real world:
    // If (userId) fetchUserFromApi(userId) <-- check if user exists

    const state = reactive ({
      user: user[userId.value-1] || user[0] // remove user[0] later
    })

    return {
      user,
      state,
      userId
    }
  }
}
</script>
