import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {faBoxArchive, faMoon, faUser, faPeopleGroup, faAngleDown, faTriangleExclamation, faPlus, faBold, faItalic, faStrikethrough, faUnderline, faQuoteRight, faLink, faImage} from '@fortawesome/free-solid-svg-icons'
import { faMessage, faComments, faBell } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faBoxArchive, faUser, faPeopleGroup, faAngleDown, faMessage, faBell, faMoon, faComments, faTriangleExclamation, faPlus, faBold, faItalic, faStrikethrough, faUnderline, faQuoteRight, faLink, faImage)

const pinia = createPinia()

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(pinia).mount('#app')
