<template>
  <div class="bodybox">
    <h1>User Profile: {{ userdata.username }}</h1>

    <div id="nav">
      <router-link to="/profile/:userId">Profile</router-link> |
      <router-link to="/profile/:userId/blog">Blog</router-link>  <!-- is there a way to make this dynamic? -->
    </div>

    <div v-for="gr in userdata.groups" :key="gr.name">

      <h2>Groups</h2>
      {{ gr.name }}

      <h2>Permissions</h2>

      <div v-for="perms in gr.permissions" :key="perms.name">
        Name: {{ perms.name }}<br />
        Can moderate: {{ perms.can_moderate }}<br />
        Can post: {{ perms.can_post }}<br />
        Can reply: {{ perms.can_reply }}<br />
        Can view: {{ perms.can_view }}<br /><br />
      </div>

    </div>

    <router-view/>

  </div>
</template>

<script>

import axios from 'axios';


export default {

  data() {
    return {
      userdata: [],
    }
  },

  beforeMount() {
    axios.get("/api/whoami")
    .then(
        (response) => {
            this.userdata = response.data;
        })
    .catch(
        (error) => {
            console.log(error);
        });
  }

}

</script>
