<template>
<div class="bg">
  <div class="forumbox">
    <div class="title">
      <div class="icon"><font-awesome-icon icon="fa-regular fa-comments" size="2x" /></div>

      <div style="display: flex; flex-direction: column;">
        <div class="cattitle">{{ category.title }}</div>
        <div class="description">{{ category.description }}</div>
      </div>

      <div class="newpost">
        <router-link class="forumnav" v-bind:to="'/forum/' + category.id + '-' + category.title + '/new'">+ Create a New Post</router-link>
        <div class="description"><router-link to="/forum">Community</router-link> &#8608; {{ category.title }}</div>
      </div>

    </div>
    <div class="postheaders">
      <div class="column1">Author</div>
      <div class="column2">Topic</div>
      <div class="column3">Replies</div>
      <div class="column4">Posted</div>
    </div>

    <div class="categoriesbox" >
    <Thread v-for="th in threads" :key="th.id" :thread = "th" :replies ="th.replies"></Thread>
    </div>

  </div>
</div>

</template>

<script>
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import Thread from "../../components/ForumComponents/Thread";

import axios from 'axios';
export default {
  name: "ForumCategory",
  components: {Thread},
  data() {
    return {
      threads: [],
      category: {},
      id: null
    }
  },
  setup() {
    const state = reactive ({
      //category: category[categoryId.value-1]
      category: 0//category[categoryId.value-1]
    })
    return {
      state,
    }
  },
   beforeMount() {
    const route = useRoute();
    this.id = route.params.categoryId;

    // get list of threads under current category
    axios.get("/api/category/" + route.params.categoryId)
    .then(
        (response) => {
          this.threads = response.data;
        })
    .catch(
        (error) => {
            console.log(error);
        });

    // get current category's title and description
    axios.get("/api/categories")
    .then(
        (response) => {
          // To find the id of the category in the database and compare it to the routerId
          this.category = response.data.find(e => e.id === parseInt(route.params.categoryId));
        })
    .catch(
        (error) => {
            console.log(error);
        });


  }
}
</script>

<style scoped>
.bg {
  min-height: 100vh;
  display: inline-flex;
  justify-content: center;
  width: 92%;
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 1%;
}
.forumbox {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  text-align: left;
  margin-bottom: 2%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}
.title {
  display: flex;
  padding: 5px 0px 5px 15px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.title .icon {
  padding-right: 20px;
}
.cattitle {
  font-weight: 800;
  font-size: 23px;
}
.newpost {
  margin-left: auto;
  text-align: right;
}
.newpost .forumnav {
  text-decoration: none;
  background-color: #d8d8d8;
  border-radius: 5px;
  box-shadow: 3px 3px 0px rgb(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 10%;
  padding: 5px;
  color: black;
  font-weight: bold;
}
.newpost .description {
  margin-top: 10%;
  font-size: 13px;
  text-align: right;
  color: black;
}
.postheaders {
  display: flex;
  font-size: 20px;
  padding: 5px 15px;
  background-color: #d8d8d8;
}

.column1 {
  flex: 10%;
}
.column2 {
  flex: 50%;
}
.column3 {
  flex: 20%;
  text-align: center;
}
.column4 {
  flex: 20%;
}

</style>
