<template>

  <div class="gamespage">
    <h1>Games</h1>

    <a href="/games/snake">Play Snake</a>

  </div>

</template>

<script>

</script>

<style lang="scss">



</style>
