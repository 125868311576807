<!-- OVERVIEW FORUM CATEGORIES -->

<template>
<div class="bg">
  <div class="forumbox">
    <div class="title">
      <div class="column1">Categories</div>
      <div class="column2">Posts</div>
      <div class="column3">Last Active</div>
    </div>
    <Category v-for="cat in category" :key="cat.id" :category="cat"></Category>
  </div>
</div>
</template>

<script>

import axios from 'axios';
import Category from "../../components/ForumComponents/Category";

export default {
  components: {Category},
  data() {
    return {
      category: [],
    }
  },


  beforeMount() {
    axios.get("/api/categories")
    .then(
        (response) => {
          this.category = response.data;
        })
    .catch(
        (error) => {
            console.log(error);
        });
  }
}
</script>

<style scoped>
.bg {
  min-height: 100vh;
  display: inline-flex;
  justify-content: center;
  width: 92%;
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 1%;
}
.forumbox {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  text-align: left;
  margin-bottom: 2%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}
.title {
  display: flex;
  font-size: 25px;
  padding: 5px 15px;
  background-color: #ffffff;
  border-radius: 5px;
  font-weight: 500;
}
.column1 {
  flex: 50%;
  display: flex;
  padding-right: 15px;
}
.column2 {
  flex: 30%;
  text-align: center;
}
.column3 {
  flex: 20%;
  text-align: center;
}

</style>
