<!-- OVERVIEW REPLIES IN A THREAD -->

<template>
  <div class="bg">
    <div class="title">
      <div class="threadtitle">
        Title: to be implemented
        <!--TODO: title for thread-->
      </div>
      <div class="description">
        <router-link to="/forum">Community</router-link> &#8608; category
      </div>
    </div>
    <div class="forumthread">

      <div class="title">
        <div class="threadtitle navigations">
          Implement pagination here
          <!--         <router-links to="">&laquo;</router-links>
                  <div v-for="n in pagesNo" :key="n">
                  <router-links to="">{{n}}</router-links>
                  </div>
                  <router-links to="">&raquo;</router-links> -->
        </div>
      </div>

      <Reply v-for="post in posts" :key="post.id" :post="post" ></Reply>

      <div class="title">
        <div class="threadtitle navigations">
          Implement pagination here
          <!--         <router-links to="">&laquo;</router-links>
                  <div v-for="n in pagesNo" :key="n">
                  <router-links to="">{{n}}</router-links>
                  </div>
                  <router-links to="">&raquo;</router-links> -->
        </div>
      </div>

      <form class="replybox" @submit.prevent="Reply">
        <editor v-model="formData.text"/>
        <button class="forumbutton" style="margin-right: 30px;" @click="showPreview">Preview</button>
        <input type="submit" value="Submit">
      </form>

    </div>
  </div>


  <!-- <Trigger v-show="isTriggerVisible" @close="closeTrigger"/> -->
  <Modal v-show="isModalVisible" @close="closeModal"/>
  <Preview :value="value" v-show="isPreviewVisible" @close="closePreview"/>


</template>

<script>
import { useRoute } from 'vue-router';
import Editor from "../../components/ForumComponents/Editor.vue";
//import { marked } from 'marked';
//import DOMPURIFY from 'dompurify';
import Modal from "../../components/ForumComponents/Report.vue";
import Preview from "../../components/ForumComponents/Preview.vue";
//import Trigger from "../../components/ForumComponents/Trigger.vue";
import axios from 'axios';
import Reply from "../../components/ForumComponents/Reply";

export default {
  name: "ForumThread",
  components: {
    Reply,
    Editor,
    Modal,
    Preview,
    //Trigger
  },
  data() {
    return {
          value: '', //THIS IS THE VARIABLE TO GET THE CONTENT OF THE REPLY
          n: 1,
          isModalVisible: false,
          isPreviewVisible: false,
          //isTriggerVisible: false,
          //id: '',
          categoryid: '',
          categoryname: '',
          posts: [],
          thread: [],
          //all_posts: [],
          //post: {},

          formData: {
            text: ''
          }
      }
  },

  methods: {
    // TODO: has to be implemented on the backend
/*     checkTrigger() {
      if (this.state.thread.tw) {
        this.isTriggerVisible = true
      }
    },
    closeTrigger() {
      this.isTriggerVisible = false
    }, */
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showPreview() {
      this.isPreviewVisible = true;
    },
    closePreview() {
      this.isPreviewVisible = false;
    },


    Reply() {
      console.log("clickButton") 
      axios.post("/api/thread/" + this.$route.params.threadId + "/reply", this.formData)
    .then(
        (response) => {
            console.log(this.formData);
            console.log(response)

            window.location.reload()
        })
    .catch(
        (error) => {
            console.log(error);
        });
      },
  },
  beforeMount() {
    //this.checkTrigger();
    const route = useRoute();
    this.id = route.params.threadId;


    axios.get("/api/thread/" + route.params.threadId)
    .then(
        (response) => {
          this.posts = response.data;
          console.log(response.data);
        })
    .catch(
        (error) => {
            console.log(error);
        });
  }
}

</script>

<style scoped>
.bg {
  min-height: 100vh;
  display: inline-flex;
  justify-content: center;
  width: 80%;
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 1%;
}
.title {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  padding: 5px 15px 5px 15px;
  align-items: center;
}
.threadtitle {
  text-align: left;
  font-weight: 700;
  font-size: 23px;
}
.description {
  margin-left: auto;
  font-size: 13px;
}
.forumthread {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.forumthread .title {
  padding-left: 15px;
  width: auto;
  margin-top: 1.5%;
}

.reactions img {
  width: 20px;
  height: 20px;
}
.reactions button {
  background-color: transparent;
  padding: 0px 5px;
  border: none;
  cursor: pointer;
}
.reactions button:hover {
  background-color: #fdfdfd;
}

.title .navigations {
  margin-left: auto;
  font-size: 15px;
  font-weight: 200;
  display: flex;
}
.navigations router-links {
  padding: 0px 10px;
}
.navigations router-links.active {
  background-color: #fdfdfd;
  border-radius: 5px;
}
.navigations router-links:hover {
  background-color: #fdfdfd;
  border-radius: 5px;
  cursor: pointer;
}
.replybox {
  width: 100%;
  background-color: #ffffff;
  margin-top: 1.5%;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  height: 100%;
  padding: 10px 0px;
}
.forumbutton {
  text-decoration: none;
  background-color: #d8d8d8;
  border-radius: 5px;
  box-shadow: 3px 3px 0px rgb(0, 0, 0, 0.3);
  text-align: center;
  padding: 5px 30px;
  color: black;
  font-weight: bold;
  border: none;
  margin-bottom: 1%;
  cursor: pointer;
}

.reactions {
  align-self: flex-end;
  display: flex;
  margin-top: auto;
  padding-top: 15px;
}
.addedreacts {
  display: flex;
}
</style>
