<template>

  <div class="login-background">
    <div class="overlay">

      <div class="login-container">
        <div class="login-left">
          <div class="login-left-title">
            <span>Citopia </span>
          </div>
          <div>
            <font-awesome-icon class="darkmode-icon" icon="fa-solid fa-moon"></font-awesome-icon>
          </div>

          <div class="login-left-content">
            <form class="login-left-form" @submit.prevent="Login"> <!-- method="post" action="/" -->
              <div>
                <label for="login_email">E-mail</label>
                <input class="login-inputfield" type="text" id="email" name="email" required
                       v-model="formData.email" @change="validateEmail"
                       oninvalid="this.setCustomValidity('Please enter an E-mail')"
                       oninput="this.setCustomValidity('')">
              </div>
              <div style="display:block">
                <label for="login_password">Password</label>
                <input class="login-inputfield" type="password" id="password" name="password"
                       required
                       oninvalid="this.setCustomValidity('Please enter a password')"
                       v-model="formData.password"
                       oninput="this.setCustomValidity('')">
                <span id="wrongLogin" v-show="wrongLogIn" > Invalid email or password </span>
                <div style="display: inline-block">
                  <input type="checkbox" id="login_remember" name="remember">
                  <label for="Remember me">Remember me</label>
                </div>
              </div>
              <div>
                <input class="login-button" type="submit" value="Log In">
                <div>
                  <span style="font-size: 12px">Recover password</span>
                </div>
              </div>
            </form>

            <div class="login-left-signpost-container">
            <div class="login-left-signpost">
              <router-link to="/explore">
                <img src="../assets/images/Signpost-left.png" alt="Explore Citopia">
                <span class="login-left-signpost-explore">Explore</span>
              </router-link>

            </div>
            <div class="login-left-signpost">
              <router-link to="/SignUp">
                <img src="../assets/images/Signpost-right.png" alt="Register to Citopia">
                <span class="login-left-signpost-register">Register</span>
              </router-link>

            </div>
          </div>

          </div>
        </div>
        <div class="login-right">
          <div class="login-right-content">
            <img class="login-right-discoverImage" src="../assets/images/lolidk.png" alt="Discover Citopia">
            <div class="discoverImage-text">Discover <span style="font-weight: 700">Citopia!</span></div>
          </div>

          <span>Make connections with people from all over the world. Share your interests
            on the forum, play games, and dress up your avatar. We are waiting
            for you! </span>

         </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: "Login",

  data() {
    return {
      wrongEmail: false,
      wrongLogIn: false,
      formData :{
        email: '',
        password: '',
      },
    }
  },

  methods: {
    Login() {
      axios.post("/api/login", this.formData)
          .then(
              (response) => {
                this.wrongLogIn = !response.data.success;
                if (response.data.success) {
                  this.$router.push("/explore");
                }
                //console.log(response.data)
                // response object values - load them to the vue store?
              })
          .catch(
              (error) => {
                console.log(error)
              })

    },

    // this is duplicated from Signup view
    // TODO: deduplicate / move validation functionality to a shared scope
    // TODO: investigate vue built-in validation and avoid monsterous regular expressions
    validateEmail() {
      //eslint-disable-next-line
      const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRe.test(this.formData.email)) {
        this.wrongEmail = false;
      } else {
        this.wrongEmail = true;
      }
    }

  }
};
</script>

<style scoped>

#wrongLogin {
  color: red;
}

.login-background {
  min-height: calc(100vh - 30px);
  margin: -5px;
  background: url("../assets/images/skyline-loginsignup-bg.png") repeat-x fixed bottom center;
  background-size: 100%;
}

.overlay {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  overflow: auto;
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-height: 900px;
  max-width: 1200px;
  justify-content: center;
  margin: auto 20px;
}

.login-left, .login-right {
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.25);
  padding-top: 30px;
  border-radius: 10px;
  margin-bottom: 20px;

}

.login-left {
  margin-right: 20px;
  margin-left: 20px;
  flex: 0.90;
  padding: 30px 40px 10px 40px;
  text-align: left;
  min-width: 270px;
  background-color: #F5F5F5;
  position: relative;
  max-width: 350px;
}

.login-left-title {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  text-align: center;
  font-family: 'Nova Round', 'Arial', sans-serif;
  color: black;
  font-size: 55px;
  text-shadow:
      1px 0 0 white, /* Right shadow */
     -1px 0 0 white, /* Left shadow */
      0 1px 0 white, /* Bottom shadow */
      0 -1px 0 white; /* Top shadow */
}

.darkmode-icon {
  position: absolute;
  height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.login-left-content {
  display: grid;
  grid-template-rows: 3fr;
}

.login-left-form {
  display: grid;
  row-gap: 20px;
}

.login-left-signpost-container {
  display: flex;
  justify-content: space-between;
}

.login-left-signpost {
  position: relative;
  margin-top: 40px;
}

.login-left-signpost img {
  height: 35px;
  margin: 3px;
}

.login-left-signpost-register, .login-left-signpost-explore {
  position: absolute;
  color: whitesmoke;
  font-family: Roboto, sans-serif;
}

.login-left-signpost-register {
  top: 9px;
  left: 34px;
}

.login-left-signpost-explore {
  top: 9px;
  left: 46px;
}

.login-right {
  padding: 30px 30px 10px 30px;
  margin-right: 20px;
  margin-left: 20px;
  flex: 2;
  min-width: 400px;
  margin-bottom: 20px;
  background-color: #F5F5F5;
}

.login-right-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.discoverImage-text {
  position: absolute;
  font-size: 50px;
  font-style: italic;
  color: #030307;

}

.login-right-discoverImage {
  max-width: 100%;
  object-fit: scale-down;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  opacity: 0.6;
  margin-bottom: 10px;
}


.login-inputfield {
  border: none;
  border-radius: 5px;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.25));
  transition: filter 0.15ms;
  font-size: 16px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
}

.login-inputfield:focus {
  outline: none;
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.30));
}

.login-button {
  width: 100%;
  border-radius: 5px;
  background: #DAD6D4;
  padding: 8px;
}

</style>
