<template>
  <div v-if="editor" class="icons">
    <font-awesome-icon class="i" icon="fa-solid fa-bold" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"/>
    <font-awesome-icon class="i" icon="fa-solid fa-italic" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"/>
    <font-awesome-icon class="i" icon="fa-solid fa-strikethrough" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"/>
    <font-awesome-icon class="i" icon="fa-solid fa-underline" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }"/>
    <font-awesome-icon class="i" icon="fa-solid fa-quote-right" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }"/>

    <font-awesome-icon class="i" icon="fa-solid fa-link" @click="toggleLink" :class="{ 'is-active': editor.isActive('link') }"/>
    <font-awesome-icon class="i" icon="fa-solid fa-image" @click="addImage"/>
  </div>
  <bubble-menu class="bubble-menu" :tippy-options="{ animation: false }" :editor="editor" v-if="editor" v-show="editor.isActive('custom-image')">
        <button @click="editor.chain().focus().setImage({ size: 'small' }).run()" :class="{'is-active': editor.isActive('custom-image', {size: 'small'})}">
                Small
        </button>
        <button @click="editor.chain().focus().setImage({ size: 'medium' }).run()" :class="{'is-active': editor.isActive('custom-image', {size: 'medium'})}">
                Medium
        </button>
        <button @click="editor.chain().focus().setImage({ size: 'large' }).run()" :class="{'is-active': editor.isActive('custom-image', {size: 'large'})}">
                Large
        </button>
        <span style="color: #aaa">|</span>
        <button @click="editor.chain().focus().setImage({ float: 'left' }).run()" :class="{'is-active': editor.isActive('custom-image', {float: 'left'})}">
                Left
        </button>
        <button @click="editor.chain().focus().setImage({ float: 'none' }).run()" :class="{'is-active': editor.isActive('custom-image', {float: 'none'})}">
                Center
        </button>
        <button @click="editor.chain().focus().setImage({ float: 'right' }).run()" :class="{'is-active': editor.isActive('custom-image', {float: 'right'})}">
                Right
        </button>
        <span style="color: #aaa">|</span>
        <button @click="addImage">Change</button>
  </bubble-menu>
  <editor-content :editor="editor" class="reply"/>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import CustomImage from '../../extensions/custom-image'

export default {
    components: {
        EditorContent,
        BubbleMenu
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
        }
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        }
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
            StarterKit,
            Underline,
            Link.configure({
                validate: href => /^https?:\/\//.test(href),
                HTMLAttributes: {
                    class: 'link-on-forums'
                }
            }),
            CustomImage.configure({
                HTMLAttributes: {
                    class: 'custom-image'
                }
            })
            ],
            value: this.modelValue,
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
            }
        })
    },
    methods: {
        addImage() {
            const url = window.prompt('Link to the image:')

            if (url) {
                this.editor.chain().focus().setImage({ src: url }).run()
            }
        },
        toggleLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt("Link:", previousUrl)

            // cancelled
            if (url === null) {
            return
            }

            // empty
            if (url === '') {
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

            return
            }
            if (!url.includes("https://")) {
                // update link
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: "https://" + url , target: '_blank'})
                .run()
            }
            else {
                // update link
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url , target: '_blank'})
                .run()               
            }
        },
    },

        beforeUnmount() {
        this.editor.destroy()
        }
    }
</script>

<style lang="scss" scoped>
.icons {
    display: flex;
    align-items: center;
    margin: 10px 20px;
}
.icons .i {
    padding: 0px 5px;
    cursor: pointer;
}
.reply {
    margin: 0px 20px;
}
:deep(.ProseMirror) {
    margin: 10px 0px;
    text-align: left;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    height: 200px;
    overflow: auto;
    .custom-image-small {
        max-width: 200px;
    }
    .custom-image-medium {
        max-width: 500px;
    }
    .custom-image-large {
        max-width: 100%;
    }
    .custom-image-float-none {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .custom-image-float-left {
        float: left;
    }
    .custom-image-float-right {
        float: right;
    }
}
:deep(.ProseMirror) img {
    width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
        outline: 3px solid #68cef8;
    }
}

.bubble-menu {
    display: flex;
    background-color: #0d0d0d;
    padding: 0.2rem;
    border-radius: 0.5rem;
    button {
        border: none;
        background: none;
        color: #fff;
        font-size: 0.85rem;
        font-weight: 500;
        padding: 0 0.2rem;
        opacity: 0.6;
        &:hover,
        &.is-active {
            opacity: 1;
        }
        &.is-active {
            text-decoration: underline;
        }
    }
}
</style>