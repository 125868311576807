<template>
  <div class="replies">

    <div class="replyavatar">
      <router-link to="/" class="name">@{{ post.author }}</router-link>
      <div class="useravatar">
        <img src="https://media.discordapp.net/attachments/907237807184048139/933044287682248724/1.png">
        <!--<img v-bind:src="user[re.creator_id-1].avatar">-->
      </div>
    </div>

    <div class="replycontent">
      <div class="timestamps">
        <span style="">{{ humanDateElapsed(utcToLocal(new Date(post.created))) }} ago</span>
        <div style="margin-left: auto; font-size: 15px;">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" @click="showModal"
                             style="margin-right: 25px; cursor: pointer;"/>
          <span style="font-weight: 500; cursor: pointer;"
                @click="value='<blockquote><strong>@'+post.author+' said:</strong><br>'+post.text+'</blockquote><br><br>'">QUOTE</span>
        </div>
      </div>
      <div class="contents">
        <div v-html="markedSanitize(post.text)"></div>

        <!-- TAKING OUT REACTIONS UNTIL FURTHER NOTICE -->
        <!--<div v-if="state.thread.allow_reaction == true" class="reactions">
          <div v-if="re.reactions" class="addedreacts">
            <div v-for="m in re.reactions" :key="m">
              <img v-bind:src="require(`@/assets/reactions/${m}.png`)">
            </div>
          </div>
          <button><img src="../../assets/reactions/hearts.png"></button>
          <button><img src="../../assets/reactions/star.png"></button>
          <button><img src="../../assets/reactions/exclaim.png"></button>
          <font-awesome-icon style="padding:5px;" icon="fa-solid fa-plus"/>
        </div>-->
      </div>
    </div>

  </div>
</template>

<script>


import {marked} from "marked";
import DOMPURIFY from "dompurify";
import DateConv from "@/functions/date";

export default {
  name: "Reply",
  mixins: [DateConv],
  props: {
   post: {},
  },
  methods: {
    markedSanitize(content) {
      const htmlcontent = marked(content)
      const sanitizedcontent = DOMPURIFY.sanitize(htmlcontent)
      return sanitizedcontent
    }
  }
}
</script>

<style scoped>
.replies {
  display: flex;
  margin-top: 1.5%;
  width: 100%;
}
.replyavatar {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  margin-right: 2%;
  flex: 20%;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.replyavatar .name {
  text-decoration: none;
  font-weight: 600;
  margin-top: 2%;
  margin-bottom: 2%;
}
.replyavatar .useravatar {
  padding: 5px;
  background-color: #fdfdfd;
}
.replycontent {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  width: 100%;
  text-align: left;
  flex: 78%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
}
.timestamps {
  font-size: 13px;
  display: flex;
  padding-bottom: 7px;
  border-bottom: 0.5px solid grey;
}
.contents {
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>