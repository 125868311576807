<template>
  <div class="bg">
    <!-- first row -->

    <!-- announcement card -->
    <div class="infocard left">
      <div class="title">Announcements</div>
      <div class="info">
        <span>Announcing things</span>
        <span>More things SO MANY THINGS AAAAAAAAAAAAAAAAAAAAAAAAH</span>
        <span>Wow even more</span>
        <span>Last thing</span>
        <span>MORE Last thing</span>
        <span>Okay last I promise</span>
      </div>
    </div>

    <!-- news card -->
    <div class="infocard center">
      <div class="title">News</div>
      <div class="info">
<!--         <transition-group name="fade" tag="div">
        <div v-for="i in [currentIndex]" :key="i">
            <img :src="currentImg" />
        </div>
        </transition-group> -->
        <div class="imagesliders">
            <img class="fullavatar" src="https://img.freepik.com/free-photo/cloud-sky-twilight-times_74190-1765.jpg?w=2000">
        </div>
 <!--        <div class="sliderbuttons">
            <a class="prev" @click="prev" href="#">&#10094;</a>
            <a class="next" @click="next" href="#">&#10095;</a>
        </div> -->
      </div>
    </div>

    <!-- shouts card -->
    <div class="infocard right">
      <div class= "title">Shouts</div>
      <div class="info">
        <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
        <div class="tweets">
            <span><b>@username</b><br>Do you ever feel like you're a popcorn kernel waiting to pop and get your moment one day? I do. But it doesn't matter though, at the end of the day, I will never be a popcorn. No matter how much I want to. Oh, I really want to....</span>
        </div>
      </div>
    </div>

    <!-- end of first row -->

    <!-- second row -->

    <!-- user avatar card -->
    <div class="infocard mleft">
      <div class="title">@username</div>
      <div class="info">
        <img class="fullavatar" src="https://media.discordapp.net/attachments/907237807184048139/933044287682248724/1.png">
      </div>
    </div>

    <!-- friends activity card -->
    <div class="infocard mcenter">
      <div class="title">Friends' Activity</div>
        <div class="info">
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> commented on the thread "Romance or Strawberries".
          </div>
          <div class="time">
            <b>2m</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> created a thread "I love kpop, do you?".
          </div>
          <div class="time">
            <b>56m</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> changed their status.
          </div>
          <div class="time">
            <b>56m</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> entered the competition "Summer Vacation Outfit Competition".
          </div>
          <div class="time">
            <b>10h</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> entered the competition "Are You The One?".
          </div>
          <div class="time">
            <b>5m</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b> changed their status.
          </div>
          <div class="time">
            <b>56m</b>
          </div>
        </span>
      </div>
    </div>

    <!-- trending/following card -->
    <div class="infocard mright">
        <div class="title"><button @click="cat='trending'" :class="{active: cat == 'trending'}" class="trendingnav" style="float: left;">Trending</button><button @click="cat='following'" :class="{active: cat == 'following'}" class="trendingnav" style="float: right;">Following</button></div>
        <div v-if="cat=='trending'" class="info">
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Clowns are good.</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>DO YOU GUYS SEE.... WHAT IS GOING ON TO BE HONEST</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Clowns are good. AAAAAAAAAAAAAAAAAAAAAAAAH! ARE THEY?</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
           <div class="tweets">
            <b>Clowns are good.</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Clowns are good.</b><br>@username
          </div>
        </span>
        </div>
        <div v-else-if="cat='following'" class="info">
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b>
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>@username</b>
          </div>
        </span>
        </div>
      <div class="tinybutton">
          <router-link class="forumnav" to="/forum">Go to forums</router-link>
      </div>
    </div>

    <!-- end of second row -->

    <!-- last/third row -->

    <!-- new items card -->
    <div class="infocard bleft">
      <div class="title">New items in shop!</div>
      <div class="info">
        <img class="fullavatar" src="https://cdn.discordapp.com/attachments/907237807184048139/933044287682248724/1.png">
        <img class="fullavatar" src="https://cdn.discordapp.com/attachments/907237807184048139/933044287682248724/1.png">
        <img class="fullavatar" src="https://cdn.discordapp.com/attachments/907237807184048139/933044287682248724/1.png">
      </div>
    </div>

    <!-- spotlight card -->
    <div class="infocard bcenter">
      <div class="title">In the Spotlight!</div>
      <div class="info">
          image here
      </div>
        <div class="tinybutton">
          <router-link class="forumnav" to="">Go to blogpost</router-link>
        </div>
    </div>

    <!-- top list card -->
    <div class="infocard bright">
      <div class="title">Top List</div>
       <div class="info">
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Most liked post</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Most liked blogpost</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Most bought items</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
           <div class="tweets">
            <b>Most games played</b><br>@username
          </div>
        </span>
        <span class="activity">
          <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
          <div class="tweets">
            <b>Most people annoyed</b><br>@username
          </div>
        </span>
        </div>
      <div class="tinybutton">
          <router-link class="forumnav" to="">Go to statistics</router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
      return {
            cat: "trending",
            images: [
                "https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg",
                "https://cdn.pixabay.com/photo/2016/02/17/23/03/usa-1206240_1280.jpg",
                "https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg",
                "https://cdn.pixabay.com/photo/2016/12/04/19/30/berlin-cathedral-1882397_1280.jpg"
            ],
            activityArr: [
              {/* add activity of the homepage here*/},
            ],
            timer: null,
            currentIndex: 0
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* main background around cards*/
.bg {
    min-height: 100vh;
    display: inline-flex;
    justify-content: center;
    width: 92%;
    flex-flow: row wrap;
    align-content: space-around;
    padding: 1%;
}
/* ---------------------------- */

/* cards */
.infocard {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  text-align: left;
  height: 250px;
  max-height: 250px;
  margin-bottom: 2%;
  margin: 1%;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 20px;
  padding: 5px 15px;
  border-bottom: 0.5px solid #a59d9d;
  border-radius: 5px;
  background-color: #ffffff;
  font-weight: bold;
}
.info {
  padding-left: 15px;
  height: 100%;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tweets {
  padding-left: 10px;
  width: calc(80%);
  overflow-wrap: anywhere;
}
.bright {
    order: 9;
    flex: 20%;
    height: 370px;
    max-height: 370px;
}
/* ---------------------- */

/* announcement card */
.left {
    order: 1;
    flex: 20%;
}
.left .info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    overflow: auto;
}
.left span {
    overflow-wrap: anywhere;
    padding: 5px 15px;
}
.left span:nth-child(odd) {
    background: #eaeaea;
}
/* ---------------------- */

/* news card */
.center {
    order: 2;
    flex: 50%;
    height: 250px;
    max-height: 250px;
}
.center .info {
    width: 100%;
    height: 100%;
    padding: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.center .imagesliders {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
}
.center .fullavatar {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
}
.center .sliderbuttons {
    display: flex;
    width: 100%;
    font-size: 25px;
    align-content: center;
}
.center .next {
    flex: 50%;
    text-align: right;
    text-decoration: none;
    color: black;
}
.center .prev {
    flex: 50%;
    text-align: left;
    text-decoration: none;
    color: black;
}
/* ---------------------- */

/* shouts card */
.right {
    order: 3;
    flex: 20%;
}
.right .title {
    flex: 0;
}
.right .info {
    flex: 1;
    min-height: 0;
    display: flex;
}
.right .avatar {
    width: 70px;
    height: 70px;
    margin-top: 20px;
    align-self: flex-start;
}
.right .tweets {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1;
    overflow-y: auto;
}
/* ---------------------- */

/* user avatar card */
.mleft {
    order: 4;
    flex: 20%;
    height: 370px;
    max-height: 370px;
}
.mleft .info {
    text-align: center;
}
.mleft .fullavatar {
    object-fit: contain;
}
/* ---------------------- */

/* activity card */
.mcenter {
    order: 5;
    flex: 50%;
    height: 370px;
    max-height: 370px;
}
.mcenter .info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    overflow: auto;
}
.mcenter span {
    overflow-wrap: anywhere;
    padding: 8px 15px;
}
.mcenter span:nth-child(odd) {
    background: #eaeaea;
}

.mcenter .activity {
  display: flex;
  align-items: center;
}
.mcenter .time {
    flex: 10%;
    text-align: right;
}
/* ---------------------- */

/* trending/following card */
.mright {
    order: 6;
    flex: 20%;
    height: 370px;
    max-height: 370px;
}
.mright .info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    overflow: auto;
}
.mright span {
    overflow-wrap: anywhere;
    padding: 8px 15px;
}
.mright span:nth-child(odd) {
    background: #eaeaea;
}

.mright .activity {
  display: flex;
  align-items: center;
}
.mright .trendingnav {
    width: 50%;
    text-decoration: none;
    color: black;
    text-align: center;
    border: none;
    font-size: 20px;
    background-color: #ffffff;
    font-weight: bold;
    display: inline-block;
}
.mright button:hover,
.mright .active {
    background-color: #eaeaea;
    cursor: pointer;
}

.mright .trendingnavclicked {
    background-color: #eaeaea;
}
.mright .tinybutton {
    padding: 7px 10px;
    background-color: #ffffff;
}
.mright .forumnav {
    text-decoration: none;
    border: 3px solid #eaeaea;
    padding: 2px;
    border-radius: 12px;
    color: black;
}
/* ---------------------- */

/* new items card */
.bleft {
    order: 7;
    flex: 50%;
    height: 370px;
    max-height: 370px;
}
.bleft .info {
    text-align: center;
}
.bleft .fullavatar {
    object-fit: contain;
}
/* ---------------------- */

/* spotlight card */
.bcenter {
    order: 8;
    flex: 20%;
    height: 370px;
    max-height: 370px;
}
.bcenter .tinybutton {
    padding: 7px 10px;
    background-color: #ffffff;
}
.bcenter .forumnav {
    text-decoration: none;
    border: 3px solid #eaeaea;
    padding: 2px;
    border-radius: 12px;
    color: black;
}
.forumnav:hover {
    background-color: #eaeaea;
}
/* ---------------------- */

/* toplist card */
.bright .info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    overflow: auto;
}
.bright span {
    overflow-wrap: anywhere;
    padding: 8px 15px;
}
.bright span:nth-child(odd) {
    background: #eaeaea;
}

.bright .activity {
  display: flex;
  align-items: center;
}
.bright .tinybutton {
    padding: 7px 10px;
    background-color: #ffffff;
}
.bright .forumnav {
    text-decoration: none;
    border: 3px solid #eaeaea;
    padding: 2px;
    border-radius: 12px;
    color: black;
}

@media (max-width: 800px) {
    .bg {
    flex-direction: column;
    }
    .bg .infocard {
        margin-top: 2%;
        max-height: 100%;
    }
}
</style>
