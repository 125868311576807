<!-- CREATE NEW THREAD -->

<template>
<div class="bg">

  <form class="login-left-form" @submit.prevent="newPost">
  <div class="forumbox">
    <div class="header">
      <div class="title">
        Make a New Topic
      </div>
    </div>

    <div class="formdata">
      <div class="formsubdata">
        <label>Title</label>
        <input v-model="formData.title" />
      </div>
      <div class="formsubdata">
        <div style="display: flex; align-items: center;"><label>Tags</label><span style="font-size: 10px; margin-left: 1%; font-weight: 500;">you can add up to five tags</span></div>
        <input v-model="formData.tags" autocomplete="off"/>
      </div>
      <div class="formsubdata">
        <label>Trigger Warning</label>
        <div style="display: flex; align-items: center;"><input type="checkbox" id="trigger_warning"> <span style="font-size: 12px; margin-left: 5px;">Mark topic with a TW</span></div>
        <input v-model="formData.tw" />
        <span style="font-size: 10px; font-weight: 500; margin-left: 5px; margin-top: 5px;">Abuse of feature may result in a WARNING.</span>
      </div>
    </div>
    <div class="replybox">
      <editor v-model="formData.text" />
      <!--reactions disabled for now
      <div style="display: flex;margin-left: 2%; align-items: center;"><input type="checkbox" id="reactions"> <span style="font-size: 12px; margin-left: 5px; font-weight: 500;">Allow reactions on post</span></div>
      -->
    </div>

    <div class="header">
      <div class="imptbuttons">
          <button class="forumbutton" @click="showModal">Preview</button>
          <!--<button class="forumbutton" @submit.prevent="newPost">Submit</button>-->
          <input type="submit" value="Submit">
      </div>
    </div>

  </div>
  </form>


</div>
<Preview :value="value" v-show="isModalVisible" @close="closeModal" />
</template>

<script>
//import { useRoute } from 'vue-router';
import axios from 'axios';

import Editor from "../../components/ForumComponents/Editor.vue";
import Preview from "../../components/ForumComponents/Preview.vue";

export default {
  name: "ForumNewThread",
  components: {
    Editor,
    Preview
  },
  data() {
    return {
      isModalVisible: false,
      value: '',

      formData: {
        title: '',
        text: '',
        //tags: '', not implemented in backend yet
        //tw: '', not implemented in backend yet
      },

    }
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    newPost() {
      axios.post("/api/category/" + this.$route.params.categoryId + "/post", this.formData)
      .then(
          (response) => {
            console.log(response.data)
            window.location.href = "/forum/thread/" + response.data.id // TODO: need to redirect this to the newly created thread
          })
      .catch(
        (error) => {
            console.log(error)
        })
    },

  }
}

</script>

<style scoped>
.bg {
  min-height: 100vh;
  display: inline-flex;
  justify-content: center;
  width: 92%;
  flex-flow: row wrap;
  align-content: flex-start;
  padding: 1%;
}
.forumbox {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 5px 5px 0px rgb(0, 0, 0, 0.3);
  text-align: left;
  margin-bottom: 2%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.header {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
}
.title {
  font-size: 22px;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: 500;
}
.imptbuttons {
  margin-left: auto;
}
.forumbutton {
  text-decoration: none;
  background-color: #d8d8d8;
  border-radius: 5px;
  text-align: center;
  padding: 5px 15px;
  color: black;
  font-weight: bold;
  border: none;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.forumbutton:hover {
  background-color: #f8f8f8;
}
.formdata {
  display: flex;
  flex-direction: column;
  margin: 1% 2% 0% 2%;
}
.formdata input {
  width: 50%;
  border-radius: 5px;
  box-shadow: 3px 3px 0px rgb(0, 0, 0, 0.3);
  border: none;
  padding: 5px;
}
#trigger_warning, #reactions{
  width: auto;
  box-shadow: 1px 1px 0px rgb(0, 0, 0, 0.3);
  border-radius: 5px;
  border: none;
}
.formdata label {
  font-size: 20px;
  font-weight: 600;
}
.formsubdata {
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}
.replybox {
  width: 80%;
  margin-top: 1.5%;
  margin-bottom: 2%;
  margin-left: 2%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 0px;
}
</style>
