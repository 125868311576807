<template>

<div class="container">
  <div class="content">
    <Header />
  </div>
  <div class="footer" >
    <Footer />
  </div>
</div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
};
</script>

<style>
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  min-height: 100vh;
  height: auto;
  background-color: #ffdcde;
  width: 100%;
}
.container {
  min-height: 100vh;
  height: 100%;
  background: url("./assets/images/mainbgblur.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.content {
  flex: 1;
  order: 1;
  height: 100%;
}
.footer {
  order: 2;
}
</style>
