// sample values to use as stand-in

export const user = [
  {
    "id": 1,
    "username": "bob",
    "email": "bob@citopia.online",
    "profile_description": "hello there my name is bob and i really love plants. did you know that deadly nightshade and potatoes belong to the same family?",
    "avatar": "https://media.discordapp.net/attachments/907237807184048139/933044287682248724/1.png"
  },

  {
    "id": 2,
    "username": "alice",
    "email": "alice@citopia.online",
    "profile_description": "welcome to my page! i'm alice, i'm still working on my page, check back soon!",
    "avatar": "https://media.discordapp.net/attachments/907237807184048139/933044287682248724/1.png"
  },

];

export const category = [
  {
    "id": 1,
    "title": "Announcements",
    "description": "Important updates and announcements from the staff will be posted here",
    "no_threads": 9999,
  },

  {
    "id": 2,
    "title": "General",
    "description": "All discussions unrelated to Citopia belongs here",
    "no_threads": 49823,
  },

  {
    "id": 3,
    "title": "Citopia",
    "description": "All discussions related to Citopia belongs here",
    "no_threads": 58945,
  },

  {
    "id": 4,
    "title": "Official Competitions and Events",
    "description": "Competitions and events organised by staff",
    "no_threads": 985,
  },

  {
    "id": 5,
    "title": "Competitions",
    "description": "Organize a competition, or join one!",
    "no_threads": 233,
  },

  {
    "id": 6,
    "title": "Creativity",
    "description": "Art, writing, designs...",
    "no_threads": 95,
  },

  {
    "id": 7,
    "title": "18+",
    "description": "Only allowed entry for those 18 and above!",
    "no_threads": 12,
  }

];

export const thread = [
  {
    "id": 1,
    "title": "I really like food",
    "content": "my favorite is hawaiian pizza, what about y'all?",
    "replies": 3,
    "creator_id": 2,
    "category_id": 3,
    "tw": true,
    "allow_reaction": true
  },

  {
    "id": 2,
    "title": "My favorite designs",
    "content": "a list of my best works:",
    "creator_id": 2,
    "category_id": 3,
    "allow_reaction": false
  },

  {
    "id": 3,
    "title": "NEW SHOPS INCLUDED",
    "content": "There's now a new shop to eat food :D",
    "creator_id": 1,
    "category_id": 1,
    "allow_reaction": true
  }
]

export const reply = [
  {
    "id": 1,
    "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus nisl ut justo finibus, iaculis lacinia leo tempor. Sed sollicitudin, lectus eget pharetra pretium, orci metus maximus diam, at semper orci nisi ut diam. Integer tristique vulputate velit, eget elementum urna semper non. Praesent placerat velit ut mi fermentum placerat. Suspendisse consectetur bibendum sem. Curabitur consequat nisi eu quam aliquam, eu posuere nibh vehicula. Curabitur sagittis tellus a venenatis consectetur. Curabitur condimentum dapibus erat non gravida. Praesent lobortis consequat sem ut venenatis. Aliquam nunc arcu, mattis a nunc et, feugiat rutrum metus. Cras volutpat odio id diam varius consectetur. Nam lacinia ligula ipsum, fermentum blandit justo maximus in. Ut dignissim risus eleifend lacus hendrerit interdum. Nulla id sapien vitae elit lobortis dic Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus nisl ut justo finibus, iaculis lacinia leo tempor. Sed sollicitudin, lectus eget pharetra pretium, orci metus maximus diam, at semper orci nisi ut diam. Integer tristique vulputate velit, eget elementum urna semper non. Praesent placerat velit ut mi fermentum placerat. Suspendisse consectetur bibendum sem. Curabitur consequat nisi eu quam aliquam, eu posuere nibh vehicula. Curabitur sagittis tellus a venenatis consectetur. Curabitur condimentum dapibus erat non gravida. Praesent lobortis consequat sem ut venenatis. Aliquam nunc arcu, mattis a nunc et, feugiat rutrum metus. Cras volutpat odio id diam varius consectetur. Nam lacinia ligula ipsum, fermentum blandit justo maximus in. Ut dignissim risus eleifend lacus hendrerit interdum. Nulla id sapien vitae elit lobortis dic</p>",
    "creator_id": 2,
    "thread_id": 3,
    "reactions": [
      "hearts",
      "hearts",
      "star"
    ]
  },

  {
    "id": 2,
    "content": "<p>HELLO THERE i really love <strong>FOOD </strong></p><p><strong>I want this:</strong></p><p><strong><span class=\"ql-cursor\">﻿</span></strong></p>",
    "creator_id": 2,
    "thread_id": 1,
  }
];
