<template>
<div class="bg">
  <div v-if="!$route.meta.hideNavbar">
  <div class="background-header">
    <div class="online">
      <p>Online Users: 500</p>
    </div>
    <router-link to="/explore">
    <img src="https://fontmeme.com/permalink/220619/9bd7a5c6bb2154e93543a4f992dc9578.png">
    </router-link>
  </div>

  <div class="nav">
    <img src="https://www.w3schools.com/howto/img_avatar.png" class="avatar" />
    <div class="navbutton icon dropdown" @click="closeDropdown()"><font-awesome-icon icon="fa-solid fa-angle-down"/>
      <div class="dropdown-content" :class="{showdropdown: drop == 'show'}">
        <a href="#">Settings</a>
        <a href="#">Membership</a>
        <a href="#" @click="logOut()">Logout</a>
      </div>
    </div>
    <div class="navbutton icon"><font-awesome-icon icon="fa-regular fa-message" /></div>
    <div class="navbutton icon"><font-awesome-icon icon="fa-regular fa-bell" /></div>
    <router-link class="navbutton navlinks" to=""><font-awesome-icon icon="fa-solid fa-box-archive"/> Wardrobe</router-link>
    <router-link class="navbutton navlinks" to="/profile/:userId"><font-awesome-icon icon="fa-solid fa-user" /> Profile</router-link>
    <router-link class="navbutton navlinks" to="/forum"><font-awesome-icon icon="fa-solid fa-people-group" /> Community</router-link>
    <router-link class="navbutton navlinks" to=""><font-awesome-icon icon="fa-solid fa-user" /> Shops</router-link>
    <router-link class="navbutton navlinks" to="/games"><font-awesome-icon icon="fa-solid fa-user" /> Games</router-link>
    <router-link class="navbutton navlinks" to="/games"><font-awesome-icon icon="fa-solid fa-user" /> Creativity</router-link>

    <div v-if="loggedOut">
      <router-link class="navbutton navlinks" to="/"><font-awesome-icon icon="fa-solid fa-user" /> Login</router-link>
      <router-link class="navbutton navlinks" to="/signup"><font-awesome-icon icon="fa-solid fa-user" /> Sign Up</router-link>
    </div>

  </div>
  </div>

  <div class="mainbg">
    <router-view />
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "Header",
  data() {
    return {
          drop: "hide",
          loggedOut: false,
      }
  },

  beforeMount() {
    // check if user is logged in
    // TODO: when does this get updated?

      axios.get("/api/whoami")
      .then(
          (response) => {
              console.log(response.data.username)

              if(response.data.username == null) {
                console.log("no data, so not logged in")
                this.loggedOut = true;
              } else {
                console.log("yes data, so logged in")
                this.loggedOut = false;
              }
          })
      .catch(
          (error) => {
              console.log(error);
          });

  },

  methods: {
    closeDropdown() {
      if (this.drop == "show") {
        this.drop = "hide"
        return this.drop
      }
      else {
        this.drop = "show"
        return this.drop
      }
    },

    // doesn't work yet
    logOut() {
      console.log("logout");

      // invalidate session
      axios.post("/api/logout")
      .then(
          (response) => {
              if (response.status === 200 && response.data.success) {
                console.log("user has been logged out and session has been invalidated");

                // clear cookie
                // TODO - clear future logged-in specific cookies
                document.cookie = 'session=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

                // redirect to root/login
                window.location.assign("/");
              } else {
                console.error("Logout failed with status code", response.status, response?.data?.message);
              }
          })
      .catch(
          (error) => {
              console.log(error);
          });
    },

    outsideClick() {
      this.drop = "hide"
    }
  }
};
</script>

<style scoped>
.bg {
  height: 100%;
  width: 100%;
}
.background-header {
  text-align: center;
  color: #030307;
  padding: 6%;
  background-image: url("../assets/images/CitopiaHeader.png");
  background-size: cover;
}
.online {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
}
.nav {
  background-color: #fffefe;
  height: 2.5%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  box-shadow: 2px 0px 2px 0px rgba(0,0,0,0.5);
  justify-content: center;
  text-align: center;
}
.navbutton {
  display: inline-block;
  color: #030307;
  text-align: center;
  padding: 0.6% 2.5%;
  text-decoration: none;
}
.navbutton:hover {
  cursor: pointer;
}
.navlinks:hover {
  background-color: #eaeaea;
}
.icon {
  padding-left: 0.5%;
  padding-right: 0.5%;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10%;
  text-align: left;
}

.dropdown-content a {
  color: black;
  padding: 10px 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.showdropdown {
  display: block;
}
.avatar {
  width: 50px;
  height: 50px;
  margin-top: -10px;
  border-radius: 50%;
  border: 2px solid white;
}
.mainbg {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
