<template>
  <div class="login-background">
    <div class="overlay">

      <div class="login-container">
        <div class="login-left">
          <div class="login-left-title">
            <span>Join Citopia</span>
          </div>
          <div>
            <font-awesome-icon class="darkmode-icon" icon="fa-solid fa-moon"></font-awesome-icon>
          </div>
          <div class="login-left-content">

            <form class="login-left-form" @submit.prevent="Signup" id="registration">


              <div>
                <label for="username">Create your username</label><br/>
                <input class="login-inputfield" type="text" id="username" v-model="formData.username" required/>
              </div>
              <div>
                <label for="email">Your E-mail</label><br/>
                <input class="login-inputfield" type="text" id="email" required v-model="formData.email"
                       @change="validateEmail"/><br/>
                <span id="wrongInput" v-show="wrongEmail" style="color:red">Incorrect email address</span>
              </div>
              <div>
                <label for="body">Create your password</label><br/>
                <input class="login-inputfield" type="password" id="password" v-model="formData.password" required/>
              </div>

              <div>
                <label for="body">Repeat Password</label><br/>
                <input class="login-inputfield" type="password" id="password2" required v-model="formData.password2"
                       @change="isPasswordEqual"/><br/>
                <span id="wrongInput" v-show="diffPassword" style="color:red">Passwords do not match!</span>
              </div>

              <div>
                <label for="body">Date of Birth</label><br/>
                <input class="login-inputfield" type="date" id="birthday" v-model="formData.birthday" required/>
              </div>
              <div>
                <span style="font-size: 12px">By continuing with registration, you agree to the Terms of Service and Rules of Conduct</span>
              </div>
              <input class="login-button" type="submit" value="Sign Up">
              <!--<button class="login-button" type="submit">Sign Up</button> -->
              <!-- TODO: disable button if passwords don't match ("continue" signpost) -->
            </form>
          </div>

          <div class="login-left-signpost-container">
            <div class="login-left-signpost">
              <router-link to="/">
                <img src="../assets/images/Signpost-left.png" alt="Back to login">
                <span class="login-left-signpost-explore">Log in</span>
              </router-link>

            </div>
            <div class="login-left-signpost">
              <router-link to="/explore">
                <img src="../assets/images/Signpost-right.png" alt="Explore">
                <span class="login-left-signpost-register">Explore</span>
              </router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <!-- add google recaptcha -->

</template>

<script>
import axios from 'axios';

export default {
  name: 'Signup',

  data() {
    return {
      diffPassword: false,
      wrongEmail: false,

      formData: {
        email: '',
        username: '',
        password: '',
        //password2: '',
        //date: '',
      },
    }
  },

  methods: {
    Signup() {
      //axios.post("https://jsonplaceholder.typicode.com/posts", this.formData)
      axios.post("/api/register", this.formData)
          .then(
          (response) => {
              console.log(response)
              // response object values - load them to the vue store?
              // validate: response.data.success === true
              // redirect to /explore
              this.$router.push("/")
          })
          .catch(error => console.log(error))
    },

    isPasswordEqual() {
      if (this.formData.password === this.formData.password2) {
        this.diffPassword = false;
      } else {
        this.diffPassword = true;
      }
    },

    validateEmail() {
      //eslint-disable-next-line
      const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRe.test(this.formData.email)) {
        this.wrongEmail = false;
      } else {
        this.wrongEmail = true;
      }
    }

  }

}

</script>

<style scoped>


.login-background {
  min-height: calc(100vh - 30px);
  margin: -5px;
  background: url("../assets/images/skyline-loginsignup-bg.png") repeat-x fixed bottom center;
  background-size: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  overflow: auto;
}

.login-container {
  display: flex;
  position: relative;
  max-height: 900px;
  justify-content: center;
  margin: auto 20px;
}
.login-left {
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #F5F5F5;
  position: relative;
}

.login-left {
  padding: 40px 50px 10px 50px;
  text-align: left;
  margin-bottom: 55px;
}

.login-left-content {
  max-width: 250px;
}

.login-left-title {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  text-align: center;
  font-family: 'Nova Round', 'Arial', sans-serif;
  color: black;
  font-size: 55px;
  text-shadow: 1px 0 0 white, /* Right shadow */ -1px 0 0 white, /* Left shadow */ 0 1px 0 white, /* Bottom shadow */ 0 -1px 0 white; /* Top shadow */
}

.darkmode-icon {
  position: absolute;
  height: 25px;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.login-left-form {
  display: grid;
  row-gap: 20px;
}


.login-left-signpost-container {
  display: flex;
  justify-content: space-between;
}

.login-left-signpost {
  position: relative;
  margin-top: 40px;
}

.login-left-signpost img {
  height: 35px;
  margin-left: -5px;
  margin-right: -5px;
}

.login-left-signpost-register, .login-left-signpost-explore {
  position: absolute;

  color: whitesmoke;
  font-family: Roboto, sans-serif;
}

.login-left-signpost-register {
  top: 7px;
  left: 21px;
}

.login-left-signpost-explore {
  top: 7px;
  left: 45px;
}


.login-inputfield {
  border: none;
  border-radius: 5px;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.25));
  transition: filter 0.15ms;
  font-size: 16px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
}

.login-inputfield:focus {
  outline: none;
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.30));
}

.login-button {
  width: 100%;
  border-radius: 5px;
  background: #DAD6D4;
  padding: 8px;
}

</style>
