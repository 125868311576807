<template>

  <div class="snakegame">
    <h1>Game: Snake</h1>

<canvas width="400" height="400" id="game"></canvas>

  </div>

</template>

<script>

export default {
  name: 'SnakeGame',

  data() {
    return {

    }
  },

  methods: {

  }

}

</script>

<style lang="scss">


canvas {
  border: 1px solid black;
}

</style>
